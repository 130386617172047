import { roles, actions } from "../utils/const";
import { some, intersection } from "lodash";
import { State, useStore } from "../store";
import { Store } from "vuex";

const defaultPermissions = new Map();
defaultPermissions.set(actions.SHOW_MAKE_APPOINTMENT, [
  [roles.PATIENT, roles.GENERAL],
  [roles.PATIENT, roles.INTER],
  [roles.PATIENT, roles.WELBI],
]);

defaultPermissions.set(actions.SHOW_APPOINTMENT_TYPES, [
  [roles.PATIENT, roles.APRIL],
  [roles.PATIENT, roles.AXA],
  [roles.PATIENT, roles.CMP],
  [roles.PATIENT, roles.COMPENSA],
  [roles.PATIENT, roles.EUROP_ASSISTANCE],
  [roles.PATIENT, roles.GENERAL],
  [roles.PATIENT, roles.GENERALI],
  [roles.PATIENT, roles.GLOBAL_ASSISTANCE],
  [roles.PATIENT, roles.MONDIAL],
  [roles.PATIENT, roles.NATIONALE_NEDERLANDEN],
  [roles.PATIENT, roles.POLMED],
  [roles.PATIENT, roles.PZU],
  [roles.PATIENT, roles.SIGNAL_IDUNA],
  [roles.PATIENT, roles.TUZ_UBEZPIECZENIA],
  [roles.PATIENT, roles.TU_EUROPA],
  [roles.PATIENT, roles.TU_ZDROWIE],
  [roles.PATIENT, roles.WARTA],
  [roles.PATIENT, roles.MEEDY],
  [roles.PATIENT, roles.LUXMED],
]);
defaultPermissions.set(actions.EDIT_PHONE_NUMBER, [
  [roles.PATIENT, roles.APRIL],
  [roles.PATIENT, roles.AXA],
  [roles.PATIENT, roles.CMP],
  [roles.PATIENT, roles.EUROP_ASSISTANCE],
  [roles.PATIENT, roles.GENERAL],
  [roles.PATIENT, roles.GENERALI],
  [roles.PATIENT, roles.GLOBAL_ASSISTANCE],
  [roles.PATIENT, roles.INTER],
  [roles.PATIENT, roles.MONDIAL],
  [roles.PATIENT, roles.NATIONALE_NEDERLANDEN],
  [roles.PATIENT, roles.POLMED],
  [roles.PATIENT, roles.PZU],
  [roles.PATIENT, roles.SIGNAL_IDUNA],
  [roles.PATIENT, roles.TUZ_UBEZPIECZENIA],
  [roles.PATIENT, roles.TU_EUROPA],
  [roles.PATIENT, roles.TU_ZDROWIE],
  [roles.PATIENT, roles.WARTA],
  [roles.PATIENT, roles.MEEDY],
  [roles.PATIENT, roles.LUXMED],
]);
defaultPermissions.set(actions.DISABLE_MAKE_APPOITNMENT_COUNTRY, [
  [roles.PATIENT, roles.APRIL],
  [roles.PATIENT, roles.AXA],
  [roles.PATIENT, roles.CMP],
  [roles.PATIENT, roles.COMPENSA],
  [roles.PATIENT, roles.EUROP_ASSISTANCE],
  [roles.PATIENT, roles.GENERAL],
  [roles.PATIENT, roles.GENERALI],
  [roles.PATIENT, roles.GLOBAL_ASSISTANCE],
  [roles.PATIENT, roles.INTER],
  [roles.PATIENT, roles.MONDIAL],
  [roles.PATIENT, roles.NATIONALE_NEDERLANDEN],
  [roles.PATIENT, roles.POLMED],
  [roles.PATIENT, roles.PZU],
  [roles.PATIENT, roles.SIGNAL_IDUNA],
  [roles.PATIENT, roles.TUZ_UBEZPIECZENIA],
  [roles.PATIENT, roles.TU_EUROPA],
  [roles.PATIENT, roles.TU_ZDROWIE],
  [roles.PATIENT, roles.WARTA],
  [roles.PATIENT, roles.MEEDY],
  [roles.PATIENT, roles.LUXMED],
]);
defaultPermissions.set(actions.DISABLE_MAKE_APPOITNMENT_LANGUAGE, [
  [roles.PATIENT, roles.APRIL],
  [roles.PATIENT, roles.AXA],
  [roles.PATIENT, roles.CMP],
  [roles.PATIENT, roles.COMPENSA],
  [roles.PATIENT, roles.EUROP_ASSISTANCE],
  [roles.PATIENT, roles.GENERAL],
  [roles.PATIENT, roles.GENERALI],
  [roles.PATIENT, roles.GLOBAL_ASSISTANCE],
  [roles.PATIENT, roles.INTER],
  [roles.PATIENT, roles.MONDIAL],
  [roles.PATIENT, roles.NATIONALE_NEDERLANDEN],
  [roles.PATIENT, roles.POLMED],
  [roles.PATIENT, roles.PZU],
  [roles.PATIENT, roles.SIGNAL_IDUNA],
  [roles.PATIENT, roles.TUZ_UBEZPIECZENIA],
  [roles.PATIENT, roles.TU_EUROPA],
  [roles.PATIENT, roles.TU_ZDROWIE],
  [roles.PATIENT, roles.WARTA],
  [roles.PATIENT, roles.MEEDY],
  [roles.PATIENT, roles.LUXMED],
]);
defaultPermissions.set(actions.EDIT_EMAIL, [
  [roles.PATIENT, roles.APRIL],
  [roles.PATIENT, roles.AXA],
  [roles.PATIENT, roles.CMP],
  [roles.PATIENT, roles.EUROP_ASSISTANCE],
  [roles.PATIENT, roles.GENERAL],
  [roles.PATIENT, roles.GENERALI],
  [roles.PATIENT, roles.GLOBAL_ASSISTANCE],
  [roles.PATIENT, roles.INTER],
  [roles.PATIENT, roles.MONDIAL],
  [roles.PATIENT, roles.NATIONALE_NEDERLANDEN],
  [roles.PATIENT, roles.POLMED],
  [roles.PATIENT, roles.PZU],
  [roles.PATIENT, roles.SIGNAL_IDUNA],
  [roles.PATIENT, roles.TUZ_UBEZPIECZENIA],
  [roles.PATIENT, roles.TU_EUROPA],
  [roles.PATIENT, roles.TU_ZDROWIE],
  [roles.PATIENT, roles.WARTA],
  [roles.PATIENT, roles.MEEDY],
  [roles.PATIENT, roles.LUXMED],
]);
defaultPermissions.set(actions.SHOW_MARKETING_AGREEMENT, [
  [roles.PATIENT, roles.APRIL],
  [roles.PATIENT, roles.AXA],
  [roles.PATIENT, roles.CMP],
  [roles.PATIENT, roles.COMPENSA],
  [roles.PATIENT, roles.EUROP_ASSISTANCE],
  [roles.PATIENT, roles.GENERAL],
  [roles.PATIENT, roles.INTER],
  [roles.PATIENT, roles.GENERALI],
  [roles.PATIENT, roles.GLOBAL_ASSISTANCE],
  [roles.PATIENT, roles.MONDIAL],
  [roles.PATIENT, roles.NATIONALE_NEDERLANDEN],
  [roles.PATIENT, roles.POLMED],
  [roles.PATIENT, roles.PZU],
  [roles.PATIENT, roles.SIGNAL_IDUNA],
  [roles.PATIENT, roles.TUZ_UBEZPIECZENIA],
  [roles.PATIENT, roles.TU_EUROPA],
  [roles.PATIENT, roles.TU_ZDROWIE],
  [roles.PATIENT, roles.WARTA],
  [roles.PATIENT, roles.MEEDY],
  [roles.PATIENT, roles.LUXMED],
]);
defaultPermissions.set(actions.SHOW_ORDER_STEPS, [
  [roles.PATIENT, roles.APRIL],
  [roles.PATIENT, roles.AXA],
  [roles.PATIENT, roles.CMP],
  [roles.PATIENT, roles.COMPENSA],
  [roles.PATIENT, roles.EUROP_ASSISTANCE],
  [roles.PATIENT, roles.GENERAL],
  [roles.PATIENT, roles.GENERALI],
  [roles.PATIENT, roles.GLOBAL_ASSISTANCE],
  [roles.PATIENT, roles.INTER],
  [roles.PATIENT, roles.MONDIAL],
  [roles.PATIENT, roles.NATIONALE_NEDERLANDEN],
  [roles.PATIENT, roles.POLMED],
  [roles.PATIENT, roles.PZU],
  [roles.PATIENT, roles.SIGNAL_IDUNA],
  [roles.PATIENT, roles.TUZ_UBEZPIECZENIA],
  [roles.PATIENT, roles.TU_EUROPA],
  [roles.PATIENT, roles.TU_ZDROWIE],
  [roles.PATIENT, roles.WARTA],
  [roles.PATIENT, roles.MEEDY],
  [roles.PATIENT, roles.LUXMED],
]);
defaultPermissions.set(actions.SHOW_TRADE_AGREEMENT, [
  [roles.PATIENT, roles.APRIL],
  [roles.PATIENT, roles.AXA],
  [roles.PATIENT, roles.CMP],
  [roles.PATIENT, roles.COMPENSA],
  [roles.PATIENT, roles.EUROP_ASSISTANCE],
  [roles.PATIENT, roles.GENERAL],
  [roles.PATIENT, roles.INTER],
  [roles.PATIENT, roles.GENERALI],
  [roles.PATIENT, roles.GLOBAL_ASSISTANCE],
  [roles.PATIENT, roles.MONDIAL],
  [roles.PATIENT, roles.NATIONALE_NEDERLANDEN],
  [roles.PATIENT, roles.POLMED],
  [roles.PATIENT, roles.PZU],
  [roles.PATIENT, roles.SIGNAL_IDUNA],
  [roles.PATIENT, roles.TUZ_UBEZPIECZENIA],
  [roles.PATIENT, roles.TU_EUROPA],
  [roles.PATIENT, roles.TU_ZDROWIE],
  [roles.PATIENT, roles.WARTA],
  [roles.PATIENT, roles.MEEDY],
  [roles.PATIENT, roles.LUXMED],
]);
defaultPermissions.set(actions.SHOW_OTHER_SLOTS, [
  [roles.PATIENT, roles.APRIL],
  [roles.PATIENT, roles.AXA],
  [roles.PATIENT, roles.CMP],
  [roles.PATIENT, roles.COMPENSA],
  [roles.PATIENT, roles.EUROP_ASSISTANCE],
  [roles.PATIENT, roles.GENERAL],
  [roles.PATIENT, roles.GENERALI],
  [roles.PATIENT, roles.GLOBAL_ASSISTANCE],
  [roles.PATIENT, roles.INTER],
  [roles.PATIENT, roles.MONDIAL],
  [roles.PATIENT, roles.NATIONALE_NEDERLANDEN],
  [roles.PATIENT, roles.POLMED],
  [roles.PATIENT, roles.PZU],
  [roles.PATIENT, roles.SIGNAL_IDUNA],
  [roles.PATIENT, roles.TUZ_UBEZPIECZENIA],
  [roles.PATIENT, roles.TU_EUROPA],
  [roles.PATIENT, roles.TU_ZDROWIE],
  [roles.PATIENT, roles.WARTA],
  [roles.PATIENT, roles.MEEDY],
  [roles.PATIENT, roles.LUXMED],
]);
defaultPermissions.set(actions.SHOW_PROFILE, [
  [roles.PATIENT, roles.ALLIANZ],
  [roles.PATIENT, roles.APRIL],
  [roles.PATIENT, roles.AXA],
  [roles.PATIENT, roles.CMP],
  [roles.PATIENT, roles.COMPENSA],
  [roles.PATIENT, roles.EUROP_ASSISTANCE],
  [roles.PATIENT, roles.GENERAL],
  [roles.PATIENT, roles.GENERALI],
  [roles.PATIENT, roles.GLOBAL_ASSISTANCE],
  [roles.PATIENT, roles.INTER],
  [roles.PATIENT, roles.SALTUS],
  [roles.PATIENT, roles.MONDIAL],
  [roles.PATIENT, roles.NATIONALE_NEDERLANDEN],
  [roles.PATIENT, roles.POLMED],
  [roles.PATIENT, roles.PZU],
  [roles.PATIENT, roles.SIGNAL_IDUNA],
  [roles.PATIENT, roles.TUZ_UBEZPIECZENIA],
  [roles.PATIENT, roles.TU_EUROPA],
  [roles.PATIENT, roles.TU_ZDROWIE],
  [roles.PATIENT, roles.WARTA],
  [roles.PATIENT, roles.WELBI],
  [roles.PATIENT, roles.MEEDY],
  [roles.PATIENT, roles.LUXMED],
]);
defaultPermissions.set(actions.SHOW_VISITS, [
  [roles.PATIENT, roles.ALLIANZ],
  [roles.PATIENT, roles.APRIL],
  [roles.PATIENT, roles.AXA],
  [roles.PATIENT, roles.CMP],
  [roles.PATIENT, roles.COMPENSA],
  [roles.PATIENT, roles.EUROP_ASSISTANCE],
  [roles.PATIENT, roles.GENERAL],
  [roles.PATIENT, roles.GENERALI],
  [roles.PATIENT, roles.GLOBAL_ASSISTANCE],
  [roles.PATIENT, roles.INTER],
  [roles.PATIENT, roles.SALTUS],
  [roles.PATIENT, roles.MONDIAL],
  [roles.PATIENT, roles.NATIONALE_NEDERLANDEN],
  [roles.PATIENT, roles.POLMED],
  [roles.PATIENT, roles.PZU],
  [roles.PATIENT, roles.SIGNAL_IDUNA],
  [roles.PATIENT, roles.TUZ_UBEZPIECZENIA],
  [roles.PATIENT, roles.TU_EUROPA],
  [roles.PATIENT, roles.TU_ZDROWIE],
  [roles.PATIENT, roles.WARTA],
  [roles.PATIENT, roles.WELBI],
  [roles.PATIENT, roles.MEEDY],
  [roles.PATIENT, roles.LUXMED],
]);
defaultPermissions.set(actions.SHOW_VISIT, [
  [roles.PATIENT, roles.ALLIANZ],
  [roles.PATIENT, roles.APRIL],
  [roles.PATIENT, roles.AXA],
  [roles.PATIENT, roles.CMP],
  [roles.PATIENT, roles.COMPENSA],
  [roles.PATIENT, roles.EUROP_ASSISTANCE],
  [roles.PATIENT, roles.GENERAL],
  [roles.PATIENT, roles.GENERALI],
  [roles.PATIENT, roles.GLOBAL_ASSISTANCE],
  [roles.PATIENT, roles.INTER],
  [roles.PATIENT, roles.SALTUS],
  [roles.PATIENT, roles.MONDIAL],
  [roles.PATIENT, roles.NATIONALE_NEDERLANDEN],
  [roles.PATIENT, roles.POLMED],
  [roles.PATIENT, roles.PZU],
  [roles.PATIENT, roles.SIGNAL_IDUNA],
  [roles.PATIENT, roles.TUZ_UBEZPIECZENIA],
  [roles.PATIENT, roles.TU_EUROPA],
  [roles.PATIENT, roles.TU_ZDROWIE],
  [roles.PATIENT, roles.WARTA],
  [roles.PATIENT, roles.WELBI],
  [roles.PATIENT, roles.MEEDY],
  [roles.PATIENT, roles.LUXMED],
]);
defaultPermissions.set(actions.SHOW_ORDER, [
  [roles.PATIENT, roles.APRIL],
  [roles.PATIENT, roles.AXA],
  [roles.PATIENT, roles.CMP],
  [roles.PATIENT, roles.COMPENSA],
  [roles.PATIENT, roles.EUROP_ASSISTANCE],
  [roles.PATIENT, roles.GENERAL],
  [roles.PATIENT, roles.GENERALI],
  [roles.PATIENT, roles.GLOBAL_ASSISTANCE],
  [roles.PATIENT, roles.INTER],
  [roles.PATIENT, roles.MONDIAL],
  [roles.PATIENT, roles.NATIONALE_NEDERLANDEN],
  [roles.PATIENT, roles.POLMED],
  [roles.PATIENT, roles.PZU],
  [roles.PATIENT, roles.SIGNAL_IDUNA],
  [roles.PATIENT, roles.TUZ_UBEZPIECZENIA],
  [roles.PATIENT, roles.TU_EUROPA],
  [roles.PATIENT, roles.TU_ZDROWIE],
  [roles.PATIENT, roles.WARTA],
  [roles.PATIENT, roles.WELBI],
  [roles.PATIENT, roles.MEEDY],
  [roles.PATIENT, roles.LUXMED],
]);
defaultPermissions.set(actions.SHOW_ORDER_PERSONAL, [
  [roles.PATIENT, roles.APRIL],
  [roles.PATIENT, roles.AXA],
  [roles.PATIENT, roles.CMP],
  [roles.PATIENT, roles.COMPENSA],
  [roles.PATIENT, roles.EUROP_ASSISTANCE],
  [roles.PATIENT, roles.GENERAL],
  [roles.PATIENT, roles.GENERALI],
  [roles.PATIENT, roles.GLOBAL_ASSISTANCE],
  [roles.PATIENT, roles.INTER],
  [roles.PATIENT, roles.MONDIAL],
  [roles.PATIENT, roles.NATIONALE_NEDERLANDEN],
  [roles.PATIENT, roles.POLMED],
  [roles.PATIENT, roles.PZU],
  [roles.PATIENT, roles.SIGNAL_IDUNA],
  [roles.PATIENT, roles.TUZ_UBEZPIECZENIA],
  [roles.PATIENT, roles.TU_EUROPA],
  [roles.PATIENT, roles.TU_ZDROWIE],
  [roles.PATIENT, roles.WARTA],
  [roles.PATIENT, roles.WELBI],
  [roles.PATIENT, roles.MEEDY],
  [roles.PATIENT, roles.LUXMED],
]);
defaultPermissions.set(actions.SHOW_ORDER_SYMPTOMS, [
  [roles.PATIENT, roles.APRIL],
  [roles.PATIENT, roles.AXA],
  [roles.PATIENT, roles.CMP],
  [roles.PATIENT, roles.COMPENSA],
  [roles.PATIENT, roles.EUROP_ASSISTANCE],
  [roles.PATIENT, roles.GENERAL],
  [roles.PATIENT, roles.GENERALI],
  [roles.PATIENT, roles.GLOBAL_ASSISTANCE],
  [roles.PATIENT, roles.INTER],
  [roles.PATIENT, roles.MONDIAL],
  [roles.PATIENT, roles.NATIONALE_NEDERLANDEN],
  [roles.PATIENT, roles.POLMED],
  [roles.PATIENT, roles.PZU],
  [roles.PATIENT, roles.SIGNAL_IDUNA],
  [roles.PATIENT, roles.TUZ_UBEZPIECZENIA],
  [roles.PATIENT, roles.TU_EUROPA],
  [roles.PATIENT, roles.TU_ZDROWIE],
  [roles.PATIENT, roles.WARTA],
  [roles.PATIENT, roles.WELBI],
  [roles.PATIENT, roles.MEEDY],
  [roles.PATIENT, roles.LUXMED],
]);
defaultPermissions.set(actions.SHOW_ORDER_PAYMENT, [
  [roles.PATIENT, roles.APRIL],
  [roles.PATIENT, roles.AXA],
  [roles.PATIENT, roles.CMP],
  [roles.PATIENT, roles.COMPENSA],
  [roles.PATIENT, roles.EUROP_ASSISTANCE],
  [roles.PATIENT, roles.GENERAL],
  [roles.PATIENT, roles.GENERALI],
  [roles.PATIENT, roles.GLOBAL_ASSISTANCE],
  [roles.PATIENT, roles.INTER],
  [roles.PATIENT, roles.MONDIAL],
  [roles.PATIENT, roles.NATIONALE_NEDERLANDEN],
  [roles.PATIENT, roles.POLMED],
  [roles.PATIENT, roles.PZU],
  [roles.PATIENT, roles.SIGNAL_IDUNA],
  [roles.PATIENT, roles.TUZ_UBEZPIECZENIA],
  [roles.PATIENT, roles.TU_EUROPA],
  [roles.PATIENT, roles.TU_ZDROWIE],
  [roles.PATIENT, roles.WARTA],
  [roles.PATIENT, roles.WELBI],
  [roles.PATIENT, roles.MEEDY],
  [roles.PATIENT, roles.LUXMED],
]);
defaultPermissions.set(actions.SHOW_APPOINTMENT_SUCCESS, [
  [roles.PATIENT, roles.APRIL],
  [roles.PATIENT, roles.AXA],
  [roles.PATIENT, roles.CMP],
  [roles.PATIENT, roles.COMPENSA],
  [roles.PATIENT, roles.EUROP_ASSISTANCE],
  [roles.PATIENT, roles.GENERAL],
  [roles.PATIENT, roles.GENERALI],
  [roles.PATIENT, roles.GLOBAL_ASSISTANCE],
  [roles.PATIENT, roles.INTER],
  [roles.PATIENT, roles.MONDIAL],
  [roles.PATIENT, roles.NATIONALE_NEDERLANDEN],
  [roles.PATIENT, roles.POLMED],
  [roles.PATIENT, roles.PZU],
  [roles.PATIENT, roles.SIGNAL_IDUNA],
  [roles.PATIENT, roles.TUZ_UBEZPIECZENIA],
  [roles.PATIENT, roles.TU_EUROPA],
  [roles.PATIENT, roles.TU_ZDROWIE],
  [roles.PATIENT, roles.WARTA],
  [roles.PATIENT, roles.WELBI],
  [roles.PATIENT, roles.MEEDY],
  [roles.PATIENT, roles.LUXMED],
]);
defaultPermissions.set(actions.SHOW_APPOINTMENT_FAILED, [
  [roles.PATIENT, roles.APRIL],
  [roles.PATIENT, roles.AXA],
  [roles.PATIENT, roles.CMP],
  [roles.PATIENT, roles.COMPENSA],
  [roles.PATIENT, roles.EUROP_ASSISTANCE],
  [roles.PATIENT, roles.GENERAL],
  [roles.PATIENT, roles.GENERALI],
  [roles.PATIENT, roles.GLOBAL_ASSISTANCE],
  [roles.PATIENT, roles.INTER],
  [roles.PATIENT, roles.MONDIAL],
  [roles.PATIENT, roles.NATIONALE_NEDERLANDEN],
  [roles.PATIENT, roles.POLMED],
  [roles.PATIENT, roles.PZU],
  [roles.PATIENT, roles.SIGNAL_IDUNA],
  [roles.PATIENT, roles.TUZ_UBEZPIECZENIA],
  [roles.PATIENT, roles.TU_EUROPA],
  [roles.PATIENT, roles.TU_ZDROWIE],
  [roles.PATIENT, roles.WARTA],
  [roles.PATIENT, roles.WELBI],
  [roles.PATIENT, roles.MEEDY],
  [roles.PATIENT, roles.LUXMED],
]);
defaultPermissions.set(actions.SHOW_APPOINTMENT_RESULT, [
  [roles.PATIENT, roles.APRIL],
  [roles.PATIENT, roles.AXA],
  [roles.PATIENT, roles.CMP],
  [roles.PATIENT, roles.COMPENSA],
  [roles.PATIENT, roles.EUROP_ASSISTANCE],
  [roles.PATIENT, roles.GENERAL],
  [roles.PATIENT, roles.GENERALI],
  [roles.PATIENT, roles.GLOBAL_ASSISTANCE],
  [roles.PATIENT, roles.INTER],
  [roles.PATIENT, roles.MONDIAL],
  [roles.PATIENT, roles.NATIONALE_NEDERLANDEN],
  [roles.PATIENT, roles.POLMED],
  [roles.PATIENT, roles.PZU],
  [roles.PATIENT, roles.SIGNAL_IDUNA],
  [roles.PATIENT, roles.TUZ_UBEZPIECZENIA],
  [roles.PATIENT, roles.TU_EUROPA],
  [roles.PATIENT, roles.TU_ZDROWIE],
  [roles.PATIENT, roles.WARTA],
  [roles.PATIENT, roles.WELBI],
  [roles.PATIENT, roles.MEEDY],
  [roles.PATIENT, roles.LUXMED],
]);
defaultPermissions.set(actions.SHOW_NO_DOCTORS, [
  [roles.PATIENT, roles.APRIL],
  [roles.PATIENT, roles.AXA],
  [roles.PATIENT, roles.CMP],
  [roles.PATIENT, roles.COMPENSA],
  [roles.PATIENT, roles.EUROP_ASSISTANCE],
  [roles.PATIENT, roles.GENERAL],
  [roles.PATIENT, roles.GENERALI],
  [roles.PATIENT, roles.GLOBAL_ASSISTANCE],
  [roles.PATIENT, roles.INTER],
  [roles.PATIENT, roles.MONDIAL],
  [roles.PATIENT, roles.NATIONALE_NEDERLANDEN],
  [roles.PATIENT, roles.POLMED],
  [roles.PATIENT, roles.PZU],
  [roles.PATIENT, roles.SIGNAL_IDUNA],
  [roles.PATIENT, roles.TUZ_UBEZPIECZENIA],
  [roles.PATIENT, roles.TU_EUROPA],
  [roles.PATIENT, roles.TU_ZDROWIE],
  [roles.PATIENT, roles.WARTA],
  [roles.PATIENT, roles.WELBI],
  [roles.PATIENT, roles.MEEDY],
  [roles.PATIENT, roles.LUXMED],
]);

defaultPermissions.set(actions.SHOW_PRICE, [
  [roles.PATIENT, roles.APRIL],
  [roles.PATIENT, roles.AXA],
  [roles.PATIENT, roles.CMP],
  [roles.PATIENT, roles.COMPENSA],
  [roles.PATIENT, roles.EUROP_ASSISTANCE],
  [roles.PATIENT, roles.GENERAL],
  [roles.PATIENT, roles.GENERALI],
  [roles.PATIENT, roles.GLOBAL_ASSISTANCE],
  [roles.PATIENT, roles.MONDIAL],
  [roles.PATIENT, roles.NATIONALE_NEDERLANDEN],
  [roles.PATIENT, roles.POLMED],
  [roles.PATIENT, roles.PZU],
  [roles.PATIENT, roles.SIGNAL_IDUNA],
  [roles.PATIENT, roles.TUZ_UBEZPIECZENIA],
  [roles.PATIENT, roles.TU_EUROPA],
  [roles.PATIENT, roles.TU_ZDROWIE],
  [roles.PATIENT, roles.WARTA],
  [roles.PATIENT, roles.WELBI],
  [roles.PATIENT, roles.MEEDY],
  [roles.PATIENT, roles.LUXMED],
]);

defaultPermissions.set(actions.SHOW_SYMPTOM_CHECKER, [
  [roles.PATIENT, roles.APRIL],
  [roles.PATIENT, roles.AXA],
  [roles.PATIENT, roles.CMP],
  [roles.PATIENT, roles.COMPENSA],
  [roles.PATIENT, roles.EUROP_ASSISTANCE],
  [roles.PATIENT, roles.GENERAL],
  [roles.PATIENT, roles.GENERALI],
  [roles.PATIENT, roles.GLOBAL_ASSISTANCE],
  [roles.PATIENT, roles.MONDIAL],
  [roles.PATIENT, roles.NATIONALE_NEDERLANDEN],
  [roles.PATIENT, roles.POLMED],
  [roles.PATIENT, roles.PZU],
  [roles.PATIENT, roles.SIGNAL_IDUNA],
  [roles.PATIENT, roles.TUZ_UBEZPIECZENIA],
  [roles.PATIENT, roles.TU_EUROPA],
  [roles.PATIENT, roles.TU_ZDROWIE],
  [roles.PATIENT, roles.WARTA],
  [roles.PATIENT, roles.WELBI],
  [roles.PATIENT, roles.MEEDY],
  [roles.PATIENT, roles.LUXMED],
]);

defaultPermissions.set(actions.ORDER_CHANGE_ID, [
  [roles.PATIENT, roles.APRIL],
  [roles.PATIENT, roles.AXA],
  [roles.PATIENT, roles.CMP],
  [roles.PATIENT, roles.COMPENSA],
  [roles.PATIENT, roles.EUROP_ASSISTANCE],
  [roles.PATIENT, roles.GENERAL],
  [roles.PATIENT, roles.GENERALI],
  [roles.PATIENT, roles.GLOBAL_ASSISTANCE],
  [roles.PATIENT, roles.MONDIAL],
  [roles.PATIENT, roles.NATIONALE_NEDERLANDEN],
  [roles.PATIENT, roles.POLMED],
  [roles.PATIENT, roles.PZU],
  [roles.PATIENT, roles.SIGNAL_IDUNA],
  [roles.PATIENT, roles.TUZ_UBEZPIECZENIA],
  [roles.PATIENT, roles.TU_EUROPA],
  [roles.PATIENT, roles.TU_ZDROWIE],
  [roles.PATIENT, roles.WARTA],
  [roles.PATIENT, roles.WELBI],
  [roles.PATIENT, roles.MEEDY],
  [roles.PATIENT, roles.LUXMED],
]);

defaultPermissions.set(actions.SHOW_COMMUNICATION_TYPE_CALL, [
  [roles.PATIENT, roles.GENERAL],
  [roles.PATIENT, roles.PZU],
  [roles.PATIENT, roles.POLMED],
  [roles.PATIENT, roles.MONDIAL],
  [roles.PATIENT, roles.ALLIANZ],
  [roles.PATIENT, roles.EUROP_ASSISTANCE],
  [roles.PATIENT, roles.CMP],
  [roles.PATIENT, roles.GLOBAL_ASSISTANCE],
  [roles.PATIENT, roles.SIGNAL_IDUNA],
  [roles.PATIENT, roles.COMPENSA],
  [roles.PATIENT, roles.SALTUS],
  [roles.PATIENT, roles.TU_ZDROWIE],
  [roles.PATIENT, roles.AXA],
  [roles.PATIENT, roles.GENERALI],
  [roles.PATIENT, roles.APRIL],
  [roles.PATIENT, roles.WARTA],
  [roles.PATIENT, roles.INTER],
  [roles.PATIENT, roles.NATIONALE_NEDERLANDEN],
  [roles.PATIENT, roles.TU_EUROPA],
  [roles.PATIENT, roles.TUZ_UBEZPIECZENIA],
  [roles.PATIENT, roles.WELBI],
  [roles.PATIENT, roles.MEEDY],
  [roles.PATIENT, roles.LUXMED],
]);

defaultPermissions.set(actions.SHOW_COMMUNICATION_TYPE_CHAT, [
  [roles.PATIENT, roles.GENERAL],
]);

defaultPermissions.set(actions.SHOW_COMMUNICATION_TYPE_VIDEO, [
  [roles.PATIENT, roles.GENERAL],
]);

defaultPermissions.set(actions.CHECK_SYMPTOM_FORM, [
  [roles.PATIENT, roles.GENERAL],
  [roles.PATIENT, roles.PZU],
  [roles.PATIENT, roles.POLMED],
  [roles.PATIENT, roles.MONDIAL],
  [roles.PATIENT, roles.ALLIANZ],
  [roles.PATIENT, roles.EUROP_ASSISTANCE],
  [roles.PATIENT, roles.CMP],
  [roles.PATIENT, roles.GLOBAL_ASSISTANCE],
  [roles.PATIENT, roles.SIGNAL_IDUNA],
  [roles.PATIENT, roles.COMPENSA],
  [roles.PATIENT, roles.SALTUS],
  [roles.PATIENT, roles.TU_ZDROWIE],
  [roles.PATIENT, roles.AXA],
  [roles.PATIENT, roles.GENERALI],
  [roles.PATIENT, roles.APRIL],
  [roles.PATIENT, roles.WARTA],
  [roles.PATIENT, roles.NATIONALE_NEDERLANDEN],
  [roles.PATIENT, roles.TU_EUROPA],
  [roles.PATIENT, roles.TUZ_UBEZPIECZENIA],
  [roles.PATIENT, roles.WELBI],
  [roles.PATIENT, roles.MEEDY],
  [roles.PATIENT, roles.LUXMED],
]);
defaultPermissions.set(actions.REGISTRATION, [[roles.GENERAL]]);

defaultPermissions.set(actions.SHOW_CONSULTATION, [
  [roles.PATIENT, roles.GENERAL],
  [roles.PATIENT, roles.SALTUS],
  [roles.PATIENT, roles.COMPENSA],
]);

defaultPermissions.set(actions.SHOW_POWEREDBY, [
  [roles.GENERAL],
  [roles.PZU],
  [roles.POLMED],
  [roles.MONDIAL],
  [roles.ALLIANZ],
  [roles.CMP],
  [roles.GLOBAL_ASSISTANCE],
  [roles.SIGNAL_IDUNA],
  [roles.COMPENSA],
  [roles.SALTUS],
  [roles.TU_ZDROWIE],
  [roles.AXA],
  [roles.GENERALI],
  [roles.APRIL],
  [roles.WARTA],
  [roles.NATIONALE_NEDERLANDEN],
  [roles.INTER],
  [roles.TU_EUROPA],
  [roles.TUZ_UBEZPIECZENIA],
  [roles.WELBI],
  [roles.PATIENT, roles.MEEDY],
  [roles.LUXMED],
]);

defaultPermissions.set(actions.SHOW_NOTIFICATIONS, [
  [roles.PATIENT, roles.PZU],
  [roles.PATIENT, roles.POLMED],
  [roles.PATIENT, roles.MONDIAL],
  [roles.PATIENT, roles.ALLIANZ],
  [roles.PATIENT, roles.EUROP_ASSISTANCE],
  [roles.PATIENT, roles.CMP],
  [roles.PATIENT, roles.GLOBAL_ASSISTANCE],
  [roles.PATIENT, roles.SIGNAL_IDUNA],
  [roles.PATIENT, roles.COMPENSA],
  [roles.PATIENT, roles.SALTUS],
  [roles.PATIENT, roles.TU_ZDROWIE],
  [roles.PATIENT, roles.AXA],
  [roles.PATIENT, roles.GENERALI],
  [roles.PATIENT, roles.APRIL],
  [roles.PATIENT, roles.WARTA],
  [roles.PATIENT, roles.NATIONALE_NEDERLANDEN],
  [roles.PATIENT, roles.TU_EUROPA],
  [roles.PATIENT, roles.TUZ_UBEZPIECZENIA],
  [roles.PATIENT, roles.WELBI],
  [roles.PATIENT, roles.MEEDY],
  [roles.PATIENT, roles.LUXMED],
  [roles.PATIENT, roles.INTER],
  [roles.PATIENT, roles.GENERAL],
]);

defaultPermissions.set(actions.SHOW_CONTACT_INFORMATION, [
  [roles.PATIENT, roles.PZU],
  [roles.PATIENT, roles.POLMED],
  [roles.PATIENT, roles.MONDIAL],
  [roles.PATIENT, roles.ALLIANZ],
  [roles.PATIENT, roles.EUROP_ASSISTANCE],
  [roles.PATIENT, roles.CMP],
  [roles.PATIENT, roles.GLOBAL_ASSISTANCE],
  [roles.PATIENT, roles.SIGNAL_IDUNA],
  [roles.PATIENT, roles.SALTUS],
  [roles.PATIENT, roles.TU_ZDROWIE],
  [roles.PATIENT, roles.AXA],
  [roles.PATIENT, roles.GENERALI],
  [roles.PATIENT, roles.APRIL],
  [roles.PATIENT, roles.WARTA],
  [roles.PATIENT, roles.NATIONALE_NEDERLANDEN],
  [roles.PATIENT, roles.TU_EUROPA],
  [roles.PATIENT, roles.TUZ_UBEZPIECZENIA],
  [roles.PATIENT, roles.WELBI],
  [roles.PATIENT, roles.MEEDY],
  [roles.PATIENT, roles.LUXMED],
  [roles.PATIENT, roles.INTER],
  [roles.PATIENT, roles.GENERAL],
]);

defaultPermissions.set(actions.SHOW_ADDITIONAL_ARROWS, [
  [roles.PATIENT, roles.COMPENSA],
]);

export const hasPermission = (
  action: string,
  store: Store<State>,
  permissions = defaultPermissions
): boolean => {
  if (permissions.has(action)) {
    return some(permissions.get(action), (roles) => {
      return (
        intersection(
          roles,
          store.state.user.roles.concat(store.state.runtimeConfig.type)
        ).length === roles.length
      );
    });
  }

  return false;
};

export const usePermissions = () => {
  const store = useStore();
  const useHasPermission = (action: string) => {
    return hasPermission(action, store);
  };
  return { hasPermission: useHasPermission };
};
